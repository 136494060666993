.presentation {
  max-width: 100%;
  height: 100dvh;
  overflow: hidden;
  background-color: #f5f5f5;
}

.header {
  display: flex;
  width: 100%;
  height: 70px;
  gap: 10px;
  align-items: center;
  padding-left: 20px;
}

.slide-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.presentation-slides {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.picture-slide {
  width: 300px;
  height: 400px;
  background-color: #f5f5f5;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #5c5c5c;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
}

.picture-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.picture-slide p {
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  color: #636161;
}

.leaderboard {
  width: 900px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #333;
  font-size: 1.7rem;
}

.leaderboard th,
.leaderboard td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.leaderboard th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.leaderboard-row-0 {
  background-color: #ffd900d9;
}

.leaderboard-row-1 {
  background-color: #c0c0c0bb;
}

.leaderboard-row-2 {
  background-color: #cd8032ca;
}
